<template>
  <g>
    <shape
      :path="figure.shape"
      :is-dashed="false"
      stroke="#333333"
      :stroke-width="2"
      fill="#F6F6F6"
      class="scheme-circuit"
    />
    <g v-if="!isInNav">
      <g :class="{ 'transparent': isSectorPage }">
        <g v-if="withCorrection">
          <template v-for="(l, i) in figure.dashedShape">
            <line
              :x1="l.x1" :x2="l.x2" :y1="l.y1" :y2="l.y2"
              stroke-dasharray="6"
              stroke="#333333"
              stroke-width="1"
              :key="`line--${i}`"/>
          </template>
        </g>
        <shape
          v-if="!withCorrection"
          :path="figure.dashedShape"
          :is-dashed="true"
          stroke="#333333"
          :stroke-width="1"
          fill="none"
        />
        <shape
          :path="figure.angles"
          :is-dashed="true"
          stroke="#333333"
          :stroke-width="1"
          fill="none"
        />
        <shape-size
          v-for="(arrow, i) in arrows"
          :path="arrow.path"
          :value="arrow.value"
          :text="arrow.text"
          :key="`arrows-size--${i}`"
          :scale="figureDrawingParams.scale"
          :rollWidthOptions="rollWidthOptions"
        ></shape-size>
        <shape-size
          v-for="(side, i) in sides"
          :path="side.path"
          :value="side.value"
          :text="side.text"
          :key="`side-size--${i}`"
          :scale="figureDrawingParams.scale"
          :rollWidthOptions="rollWidthOptions"
          :withCorrection="withCorrection"
        ></shape-size>
        <zone-letter
          v-for="(item, index) in zoneLetters"
          :x="item.x"
          :y="item.y"
          :key="`letter--${item.letter}--${index}`"
        >
          {{ item.letter }}
        </zone-letter>
      </g>
      <g>
        <ruler
          v-for="ruler in 4"
          :x1="0"
          :y1="points[`y${ruler}`]"
          :x2="defaultSvgParams.width"
          :y2="points[`y${ruler}`]"
          :key="`horizontal-ruler--${ruler}`"
        />
      </g>
      <g>
        <ruler
          v-for="ruler in 6"
          :x1="points[`x${ruler}`]"
          :y1="0"
          :x2="points[`x${ruler}`]"
          :y2="defaultSvgParams.height"
          :key="`vertical-ruler--${ruler}`"
        />
      </g>
      <arrow v-if="current.params.a" :path="arrowA" :color="colors.a" />
      <arrow v-if="current.params.b" :path="arrowB" :color="colors.b" />

      <size
        v-if="current.params.a"
        :x="sizeA.x"
        :y="sizeA.y"
        :fill="colors.a"
        :text="`a = ${current.params.a}`"
      />
      <size
        v-if="current.params.b"
        :x="sizeB.x"
        :y="sizeB.y"
        :fill="colors.b"
        :text="`b = ${current.params.b}`"
      />
    </g>
  </g>
</template>

<script>
import {
  drawSizeArrowVertical,
  drawSizeArrowHorizontal
} from '@/utils/drawing'
import Shape from '@/components/dump/Figures/figure-elements/Shape'
import Size from '@/components/dump/Figures/figure-elements/Size'
import Ruler from '@/components/dump/Figures/figure-elements/Ruler'
import Arrow from '@/components/dump/Figures/figure-elements/Arrow'
import ZoneLetter from '@/components/dump/Figures/figure-elements/ZoneLetter'
import ShapeSize from '@/components/dump/Figures/figure-elements/ShapeSize'
import figureCommonSettings from '@/mixins/figureCommonSettings'

export default {
  components: { ShapeSize, ZoneLetter, Arrow, Ruler, Size, Shape },
  props: {
    isSectorPage: {
      type: Boolean,
      default: false
    },
    isInNav: {
      type: Boolean
    },
    buildingHeight: {
      type: Number,
      default: 1,
      required: true
    },
    current: {
      type: Object,
      default: () => ({}),
      required: true
    },
    rollWidthOptions: {
      type: Object,
      default: null
    }
  },
  name: 'Rectangle',
  mixins: [figureCommonSettings],
  computed: {
    arrowA() {
      const { x1, y1, y2 } = this.points
      const x = x1 - this.arrowsOffset
      return drawSizeArrowVertical(x, y1, y2)
    },
    arrowB() {
      const { x1, x2, y2 } = this.points
      const y = y2 + this.arrowsOffset
      return drawSizeArrowHorizontal(x1, y, x2)
    },
    sizeA() {
      const { x1, y1, y2 } = this.points
      return {
        x: x1 - this.arrowsOffset,
        y: (y1 + y2) / 2
      }
    },
    sizeB() {
      const { x1, x2, y2 } = this.points
      return {
        x: (x1 + x2) / 2,
        y: y2 + this.arrowsOffset
      }
    },
    zoneLetters() {
      const { x1, x2, y1, y2 } = this.points

      return this.getLetters(x1, x2, y1, y2)
    }
  }
}
</script>

<style scoped></style>
